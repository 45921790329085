<template>
  <div class="main">


    <div class="main__top">


      <div
          @click="product_show=true"
          class="add__btn mb-4"
      >
        <p>Добавить товар</p>
        <i

            class="mdi mdi-plus">
        </i>
      </div>

      <i
          @click="$router.push('/cash')"
          class="back mdi mdi-keyboard-backspace">
      </i>
    </div>

    <h3 class="mb-2">Категории</h3>
    <div
        class="cash__categories mb-4"
    >
      <div
          v-for="(category,category_index) in categories" :key="category_index"
          class="cash__categories--item"
          @click="getProductsByCategory(category.id)"
      >

        <p>{{ category.name }} </p>

      </div>

    </div>

    <div>
      <input
          v-model="search"
          class="good__search"
          type="text"
          placeholder="Поиск..."
      >
    </div>


    <div class="products">

        <div
            v-for="(product,index) in products"
            :key="index"
            class="product__item"
        >

          <p><b>Название:</b>{{ product.name }}</p>
          <p><b>Описание:</b>{{ product.description }}</p>
          <p><b>Количество:</b>{{ product.quantity }}</p>
          <p><b>Себестоимость:</b>{{ product.self_price }} тнг</p>
          <p><b>Цена:</b>{{ product.price }} тнг</p>

          <p class="mb-2"><b>Вид измерение:</b>{{ getCountType(product.count_type) }}</p>

          <div class="product__actions">
            <div @click="editProduct(product)">
              <i class="mdi mdi-lead-pencil"></i>
            </div>
            <div @click="deleteProduct(product)">
              <i class="mdi mdi-delete-forever"></i>
            </div>
          </div>
        </div>
    </div>

    <v-pagination
        v-if="pageCount"
        v-model="page"
        :length="pageCount"
        :total-visible="3"
    ></v-pagination>

    <v-dialog width="50%" v-model="product_delete">
      <v-card >

        <div class="product__deletion">

          <h3>Удалить товар?</h3>

          <div class="product__deletion__rows">
            <v-btn
                type="submit"
                class="primary mr-2"
                @click="deleteProductAction()"
            >
              <p>Да</p>
            </v-btn>
            <v-btn
                type="submit"
                class="red"
                @click="product_delete=false"
            >
              <p>Нет</p>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog width="50%" v-model="category_show">
      <v-card >
        <div class="pa-5 item__column product_control">
          <p class="product_control__title">Добавить категорию</p>

          <div class="item__column product__control__label">
            <p >Название</p>
            <input v-model="name" required/>
          </div>

          <button
              type="submit"
              class="product__control__btn item__pointer"
              @click="saveCategory()"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog width="50%" v-model="product_show">
      <v-card >
        <div class="pa-5 item__column product_control">
          <p class="product_control__title mb-4">Добавить товар</p>

          <v-select
              :items="categories"
              item-text="name"
              item-value="id"
              label="Выберите категорию"
              v-model="category"
              outlined
          ></v-select>
          <div class="item__column product__control__label">
            <p >Название</p>
            <input v-model="name" required/>
          </div>
          <div class="item__column product__control__label">
            <p>Количество</p>
            <input type="tel" v-model="quantity" required/>
          </div>

          <div class="item__column product__control__label">
            <p>Себестоимость</p>
            <input type="tel" v-model="self_price" required/>
          </div>
          <div class="item__column product__control__label">
            <p>Розничная цена</p>
            <input type="tel" v-model="price" required/>
          </div>

          <div class="item__column product__control__label">
            <p>Тип измерение</p>
<!--            <input v-model="count_type" required/>-->

            <v-select
                :items="count_types"
                item-text="label"
                item-value="value"
                label="Тип измерение"
                v-model="count_type"
                outlined
            ></v-select>
          </div>

          <div class="item__column product__control__label">
            <p>Описание</p>
            <textarea  rows="8" v-model="description" required/>
          </div>

          <button
              type="submit"
              class="product__control__btn item__pointer"
              @click="saveProduct()"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
  name: "Mailing",
  components: {

  },
  data() {
    return {
      search: '',
      pageCount: 0,
      page: 1,
      category: '',
      categories: [],
      count_types: [
        {
          label: 'по шт',
          value: 'count'
        },
        {
          label: 'по кг',
          value: 'kg'
        },
        {
          label: 'по литр',
          value: 'litr'
        }
      ],
      product_delete: false,
      product_id: '',
      text: '',
      name: '',
      quantity: '',
      price: '',
      count_type: '',
      description: '',
      product_show: false,
      category_show: false,
      products: [],
      self_price: ''
    }
  },
  methods: {
    getProductsByCategory(id, page = null) {
      this.category_id = id
      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?category_id=${id}&page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getCountType(count_type) {
      if (count_type === 'count') {
        return 'по шт';
      }
    },
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
      .then((res) => {
        this.categories = res.data
      })
      .catch((error) => {
        console.log(error)
      });
    },
    deleteProductAction() {

      this.$axios({
        method: 'delete',
        url: `${this.$API_URL}product/${this.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    deleteProduct(product) {
      this.product_delete = true
      this.product_id = product.id
    },
    editProduct(product) {
      this.product_show = true
      this.name = product.name
      this.description = product.description
    },
    saveCategory() {
      let data = {
        name: this.name

      }
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    getProducts(page=null) {

      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?page=${page}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
          })
          .catch((error) => {
            console.log(error)
          });
    },
    saveProduct() {
      let data = {
        name: this.name,
        quantity: this.quantity,
        price: this.price,
        count_type: this.count_type,
        description: this.description,
        category_id: this.category,
        self_price: this.self_price
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}product`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.getProducts()
            this.product_show = false
          })
          .catch((error) => {
            console.log(error)
          });
    },
    debounceSearchProduct: debounce(function () {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?search=${this.search}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    searchProduct() {
      this.debounceSearchProduct()
    },
  },
  mounted() {
    this.getCategories();
  },
  watch: {
    page(newPage) {
      this.getProducts(newPage);
    },
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id);
      }
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  textarea {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }
  button {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;
    p {
      margin-bottom: 0;
      font-weight: bold;
      color: white;
    }
  }
}

.product_control {
  input,textarea {
    border: 1px solid black;
    padding: 15px;
  }

  .product__control__label {
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .product__control__btn {
    padding: 10px;
    background: #0069ff;
    color: white;
    p {
      margin-bottom: 0;
    }
  }

  .product_control__title {
    font-size: 24px;
  }
}

.product__item {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin-bottom: 15px;

  b {
    margin-right: 5px;
  }

  .product__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      i {
        font-size: 18px;
      }
    }

    div:hover {
      opacity: 0.8;
    }
  }
}

.product__deletion {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;


  padding: 20px;

  .product__deletion__rows {
    display: flex;
    flex-direction: row;
    margin-top: 30px;

  }
  button {
    padding: 10px;
    background: #0069ff;
    color: white;

    p {
      margin-bottom: 0;

    }
  }
}

.main__top {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  .back {
    font-size: 32px;
  }
  .back:hover {
    opacity: 0.8;
    color: orangered;

  }
}

.add__btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #0069ff;
  padding: 5px;
  border-radius: 5px;
  color: white;

  p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.main {
   width: 100%;
   padding: 20px;
   height: 100vh;
   overflow-y: scroll;
}

.products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;

  .product__item {
    margin-right: 15px;
    min-width: 220px;
  }

}

.good__search {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
}

.cash__categories {
  display: flex;
  flex-wrap: wrap;
  .cash__categories--item {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 3px;
    padding: 10px;
  }
}
</style>
